.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.playerRowHeader {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.serverRowHeader {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.modsRowHeader {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.creditsRowHeader {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.statsRowHeader {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.PlayerContainer {
  border: 1px solid black;
  font-weight: 500;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.ServerContainer {
  border: 1px solid black;
  font-weight: 700;
  width: auto;
  background-color: rgba(255, 255, 255, 0.1);
}

.ModsContainer {
  border: 1px solid black;
  font-weight: 500;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.StatsContainer {
  border: 1px solid black;
  font-weight: 500;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.CreditsContainer {
  border: 1px solid black;
  font-weight: 700;
  width: auto;
}

.NavbarContainer {
  font-weight: 400;
  cursor: pointer;
}

.modsCardClass {
  min-height: 420px;
  max-height: auto;
  margin: 5px;
}

.statsCardClass {
  text-align: center;
  font-weight: 700;
  min-height: 100px;
  max-height: 100px;
  margin: 5px;
}

.statsGenericCardClass {
  text-align: center;
  font-weight: 700;
  min-height: 100px;
  max-height: 100px;
  margin: 5px;
}
.SinjarBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/hillside.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}
.OilfieldBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/refinery.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.TownBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/hideout.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.FarmhouseBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/farmhouse.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.PrecinctBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/precinct.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MountainBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/summit.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.CanyonBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/crossing.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.CompoundBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/outskirts.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MinistryBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/ministry.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.PowerplantBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/powerplant.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.BuhrizBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/buhriz.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.TellBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/tell.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.BabBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/bab.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.CompoundCityLandscapeBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/compoundcity.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.HighlandsBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/highlands.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MountainPassBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/mountainpass.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MacedoniaBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/macedonia.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.PanzerschlachtBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/panzerschlacht.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.FrostBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/frost.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.ShedsBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/sheds.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.PrisonBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/prison.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.PalujaBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/paluja.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.ToroBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/toro.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.DiplomacyBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/diplomacy.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.CoastlineBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/coastline.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.LastlightBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/lastlight.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MintaqahBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/mintaqah.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.MudvillageBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/mudvillage.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.OasisBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/oasis.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.SaltmineBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/saltmine.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.SultanofdrugsBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/sultanofdrugs.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.TempleBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/temple.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.SafehouseBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/safehouse.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.CitadelBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/citadel.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.GapBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/gap.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.DefaultBG {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.5)
    ),
    url(./images/ins_bg.jpg);
  border: none;
  background-size: auto;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}

.ErrorBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(./images/error.jpg);
  border: none;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
  color: red;
  font-weight: 700;
  text-shadow: 1px 2px black;
  text-align: center;
  padding-top: 100px;
}

.notsupported {
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
}

.star {
  color: yellow;
  outline-color: yellow;
  padding-left: 5px;
  font-size: x-large;
}

.pro {
  color: black;
  padding-left: 5px;
  font-size: x-large;
}

@keyframes icon-zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .pro {
    animation: icon-zoom infinite 6s forwards;
  }
}

.copyright {
  color: white;
  padding-left: 5px;
  font-size: x-large;
}

.code {
  font-weight: bold;
  color: black;
}

.grIcon path {
  stroke: white;
}

.rank {
  color: yellow;
  outline-color: black;
  font-size: x-large;
}

.rank-green {
  color: green;
  outline-color: black;
  font-size: x-large;
}

.rank-purple {
  color: purple;
  outline-color: black;
  font-size: x-large;
}
